<template>
    <MainLayout
      class="content"
    >
      <template #navigation>
        <header-menu />
        <personal-menu
          :user-name="userName"
          :menu-items="personalMenuItems"
          :user-pic="userAvatar"
        >
          <template #secondaryContent>
            <user-manager
              :managerData="userManagerData"
            />
          </template>
        </personal-menu>
        <locations-list-mobile
          class="content__locations-list-mobile"
          v-if="currentLocation && currentLocation.name"
          :current-location="currentLocation"
          @toggle-location-menu="toggleLocationMenu"
        />
        <burger-menu
          @burger-toggle="burgerToggleHandler"
          :is-close="triggerCloseBurger"
        />
      </template>
      <template #content>
        <div class="content__outer-content">
          <mobile-menu-dropdown
            :is-open="isOpenMenuDropdown"
            :item-list="mobileMenuListItems"
            @item-click="mobileMenuClickHandler"
          >
            <template #secondaryContent>
              <user-manager
                :managerData="userManagerData"
              />
            </template>
          </mobile-menu-dropdown>
        </div>
        <div class="content__mobile-controls">
          <slot name="mobile-controls"></slot>
        </div>
        <div
          class="content__main"
          :class="{
            'content__main--no-padding': noPadding,
          }"
        >
          <slot name="main-content"></slot>
        </div>
      </template>
      <template #mobile-stub>
        <slot name="mobile-stub"></slot>
      </template>
    </MainLayout>
</template>

<script>

import MainLayout from '@/layouts/MainLayout.vue';
import BurgerMenu from '@/components/BurgerMenu/BurgerMenu.vue';
import MobileMenuDropdown from '@/components/MobileMenuDropdown/MobileMenuDropdown.vue';
import LocationsListMobile from '@/ui-kit/LocationsListMobile/LocationsListMobile.vue';
import HeaderMenu from '@/components/HeaderMenu/HeaderMenu.vue';
import PersonalMenu from '@/components/PersonalMenu/PersonalMenu.vue';
import UserManager from '@/components/UserManager/UserManager.vue';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { BURGER_MENU_ITEMS_LIST } from '@/constants/common';

export default {
  name: 'contentLayout',
  components: {
    MainLayout,
    BurgerMenu,
    MobileMenuDropdown,
    LocationsListMobile,
    HeaderMenu,
    PersonalMenu,
    UserManager,
  },
  props: {
    noPadding: {
      type: Boolean,
      default: false,
    },
    locationsList: {
      type: Array,
      default: () => [],
    },
    currentLocation: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['locationChange'],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    const userState = store.state.user;
    const isOpenMenuDropdown = ref(false);
    const isOpenLocationDropdown = ref(false);
    const mobileMenuListItems = ref([]);
    const triggerCloseBurger = ref(false);
    const personalMenuItems = computed(() => userState.personalMenuItems);
    const userName = computed(() => userState.profileSettings.name.value);
    const userAvatar = computed(() => userState.profileSettings.avatar.value);
    const userManagerData = computed(() => userState.managerData);

    const burgerToggleHandler = () => {
      mobileMenuListItems.value = BURGER_MENU_ITEMS_LIST;
      isOpenMenuDropdown.value = !isOpenMenuDropdown.value;
      isOpenLocationDropdown.value = false;
    };

    const mobileMenuClickHandler = async (item) => {
      if (isOpenLocationDropdown.value) {
        emit('location-change', item);
        return;
      }

      if (item === 'logout') {
        await store.dispatch('user/logout');
        await router.push('/login');
      }
    };

    const toggleLocationMenu = () => {
      mobileMenuListItems.value = props.locationsList;
      isOpenLocationDropdown.value = !isOpenLocationDropdown.value;
      triggerCloseBurger.value = !triggerCloseBurger.value;
      isOpenMenuDropdown.value = false;
    };

    const locationChangeHandler = (id) => {
      emit('locationChange', id);
    };

    return {
      isOpenMenuDropdown,
      isOpenLocationDropdown,
      mobileMenuListItems,
      triggerCloseBurger,
      personalMenuItems,
      userAvatar,
      userName,
      burgerToggleHandler,
      mobileMenuClickHandler,
      toggleLocationMenu,
      locationChangeHandler,
      userManagerData,
    };
  },
};
</script>

<style lang="scss">
.content {
  width: 100%;

  &__main {
    display: flex;
    padding: 32px 24px 0 18px;

    &--no-padding {
      padding: 0;
    }
  }

  &__outer {
    .tabs {
      padding-left: 18px;

      &__list {
        border-top: none;
        border-bottom: 1px solid $grey200;
      }
    }
  }

  &__mobile-controls {
    display: none;
  }

  &__outer-content {
    display: none;
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .content {
    &__outer {
      background-color: $grey100;
      border-bottom: 1px solid $grey200;

      .tabs {
        &__list {
          border-bottom: none;
        }
      }
    }

    &__mobile-controls {
      display: none;
      /* position: initial;
      width: 100%;
      height: initial;
      border-bottom: 1px solid $grey200; */
    }

    &__locations-list-mobile {
      display: none;
    }

    &__outer-content {
      display: block;
      width: 100%;
      position: fixed;
      z-index: 3;
      top: 45px;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .content {
    &__outer {
      display: none;
    }

    &__main {
      padding: 55px 24px 32px;
    }

    &__side-column {
      position: fixed;
    }

    &__columns {
      padding: 45px 0 0;
    }

    &__mobile-controls {
      display: none;
      /* display: block;
      position: fixed;
      z-index: 3; */
    }
  }
}
</style>

<template>
  <header class="main-header">
    <div class="main-header__logo">
      <div class="main-header__logo-container">
        <svg
          class="main-header__logo-img"
          width="45"
          height="32"
          viewBox="0 0 45 32"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:svg="http://www.w3.org/2000/svg"
        >
          <defs>
            <clipPath id="clip0_7291_12407">
              <rect
                fill="white"
                height="24"
                id="svg_1"
                width="107.93"
              />
            </clipPath>
          </defs>
          <g>
            <path
              d="m18.81,27.04c-2.45,1.47 -5.63,0.67 -7.11,-1.78l-7.96,-13.28c-1.47,-2.45
              -0.68,-5.64 1.78,-7.11c2.45,-1.47 5.63,-0.67 7.1,1.78l7.97,13.28c1.47,2.46
              0.67,5.64 -1.78,7.11l0,0z"
              fill="#2B54E2"
              id="svg_3"
            />
            <path
              d="m23.71,4.13c3.28,0 5.94,2.65 5.94,5.93c0,1.2 -0.36,2.32 -0.98,3.26c-0.35,0.53
              -0.37,1.21 -0.06,1.76c0.32,0.55 0.91,0.88 1.55,0.86c0.08,-0.01
              0.16,-0.01 0.24,-0.01c0.08,0 0.16,0 0.24,0.01c0.64,0.02
              1.23,-0.31 1.55,-0.86c0.31,-0.55 0.29,-1.23 -0.06,-1.76c-0.62,-0.94
              -0.98,-2.06 -0.98,-3.26c0,-3.28 2.66,-5.93 5.94,-5.93c3.26,0.01 5.9,2.66
              5.9,5.93c0,3.26 -2.65,5.92 -5.92,5.92c-0.08,0 -0.17,-0.01 -0.25,-0.01c-0.63,-0.03
              -1.23,0.31 -1.54,0.86c-0.31,0.55 -0.28,1.23 0.07,1.76c0.62,0.95 0.98,2.08
              0.97,3.3c-0.02,3.26 -2.66,5.88 -5.92,5.88c-3.27,0 -5.92,-2.65
              -5.92,-5.92c0,-1.2 0.36,-2.32 0.98,-3.25c0.35,-0.54 0.38,-1.22
              0.06,-1.77c-0.31,-0.55 -0.91,-0.89 -1.54,-0.86c-0.08,0 -0.16,0.01
              -0.25,0.01c-3.27,0 -5.92,-2.66 -5.92,-5.92c0,-3.27 2.65,-5.92
              5.91,-5.93l-0.01,0z"
              fill="#2B54E2"
              id="svg_4"
            />
          </g>
        </svg>
      </div>
      <a
        href="/"
        class="main-header__logo-text-img"
      >
        <img
          src="../../assets/logo-text.svg"
          alt="logo"
        >
      </a>
    </div>
    <div class="main-header__navigation">
      <slot name="navigation"></slot>
    </div>
  </header>
</template>

<script>
export default {
  name: 'main-header',
};
</script>

<style lang="scss">
.main-header {
  display: flex;
  width: 100%;
  padding: 12px 32px 12px 20px;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 2px solid $grey200;

  &__logo {
    display: flex;
    align-items: center;

    &-container {
      position: relative;
    }

    &-img {
      transition: 0.5s;

      #svg_3,#svg_4 {
        transition: 0.5s ease-in-out;
      }

      &:hover {
        cursor: pointer;

        #svg_3 {
          opacity: 0;
        }

        #svg_4 {
          animation: shakeIn 2s ease-in-out 0s infinite normal both;
        }
      }
    }
  }

  &__navigation {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;

    .role-toggler__wrapper {
      order: 1;
    }

    .header-menu {
      order: 2;
    }

    .personal-menu__wrapper {
      order: 3;
    }
  }

  &__burger-menu {
    display: none;
  }

  &__location-mobile {
    display: none;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .main-header {
    position: sticky;
    top: -1px;
    left: 0;
    z-index: 3;
    height: 45px;
    padding: 6px 26px 6px 20px;
    background-color: #fff;

    &__logo {
      height: 18px;
      display: flex;

      &-img {
        height: 100%;
      }
    }

    &__burger-menu {
      display: block;
    }

    &__location-mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      padding: 0 8px;
      margin-left: auto;
      margin-right: 24px;
      border-radius: 4px;
      color: $blue500;
      font-size: $font-size-small;
      font-weight: $font-weight-medium;
      background: $blue100;

      a, button {
        color: $blue500;
        text-decoration: none;
        border: none;
        background: transparent;
      }
    }

    &__navigation {
      .role-toggler__wrapper {
        order: initial;
      }

      .header-menu {
        order: initial;
      }

      .personal-menu__wrapper {
        order: initial;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .main-header {
    border-bottom: 1px solid $grey200;

    &__logo {
      height: 15px;
      display: flex;

      &-img {
        height: 100%;
      }
    }
  }
}

@import '/src/styles/keyframes.scss';
</style>

<template>
    <MainLayout
      class="two-column-layout"
    >
      <template #navigation>
        <slot name="navigation"></slot>
        <burger-menu
          @burger-toggle="burgerToggleHandler"
          :is-close="triggerCloseBurger"
        />
      </template>
      <template #content>
        <div class="two-column-layout__mobile-controls">
          <slot name="mobile-controls"></slot>
        </div>
        <div class="two-column-layout__outer-content">
          <mobile-menu-dropdown
            :is-open="isOpenMenuDropdown"
            :item-list="mobileMenuListItems"
            @item-click="mobileMenuClickHandler"
          >
            <template #secondaryContent>
              <user-manager
                :managerData="userManagerData"
              />
            </template>
          </mobile-menu-dropdown>
        </div>
        <div class="two-column-layout__inner">
          <div
            class="two-column-layout__main"
            :class="{
            'content__main--no-padding': noPadding,
          }"
          >
            <slot name="main-content"></slot>
          </div>
          <div class="two-column-layout__side">
            <slot name="side-content"></slot>
          </div>
        </div>
        <div class="two-column-layout__after-inner">
          <slot name="after-inner-content"></slot>
        </div>
      </template>
    </MainLayout>
</template>

<script>

import MainLayout from '@/layouts/MainLayout.vue';
import { ref, computed } from 'vue';
import { BURGER_MENU_ITEMS_LIST } from '@/constants/common';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import BurgerMenu from '@/components/BurgerMenu/BurgerMenu.vue';
import UserManager from '@/components/UserManager/UserManager.vue';
import MobileMenuDropdown from '@/components/MobileMenuDropdown/MobileMenuDropdown.vue';

export default {
  name: 'twoColumnLayout',
  components: {
    MainLayout,
    BurgerMenu,
    MobileMenuDropdown,
    UserManager,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const isOpenMenuDropdown = ref(false);
    const mobileMenuListItems = ref([]);
    const triggerCloseBurger = ref(false);
    const userManagerData = computed(() => store.state.user.managerData);

    const burgerToggleHandler = () => {
      mobileMenuListItems.value = BURGER_MENU_ITEMS_LIST;
      isOpenMenuDropdown.value = !isOpenMenuDropdown.value;
    };

    const mobileMenuClickHandler = async (item) => {
      if (item === 'logout') {
        await store.dispatch('user/logout');
        await router.push('/login');
      }
    };

    return {
      triggerCloseBurger,
      isOpenMenuDropdown,
      mobileMenuListItems,
      mobileMenuClickHandler,
      burgerToggleHandler,
      userManagerData,
    };
  },
};
</script>

<style lang="scss">
.two-column-layout {
  &__inner {
    display: flex;
    max-width: 1920px;
    margin: 0 auto;
    padding: 60px 32px;
  }

  &__after-inner {
    display: flex;
    max-width: 1920px;
    margin: 0 auto;
  }

  &__main {
    width: 74.5%;
  }

  &__side {
    flex-grow: 2;
    margin-left: 24px;
  }

  &__outer {
    .tabs {
      padding-left: 18px;

      &__list {
        border-top: none;
        border-bottom: 1px solid $grey200;
      }
    }

    &-content {
      display: none;
    }
  }

  &__mobile-controls {
    display: none;
  }
}

@media screen and (max-width: $tablet-breakpoint ) {
  .two-column-layout {
    &__inner {
      flex-direction: column;
      padding: 0;
    }
    &__after-inner {
      flex-direction: column;
      padding: 0;
    }

    &__main {
      padding: 57px 24px 50px 24px;
      width: 100%;
    }

    &__mobile-controls {
      position: initial;
      width: 100%;
      height: initial;
      border-bottom: 1px solid $grey200;
    }

    &__outer {
      &-content {
        display: block;
        width: 100%;
        position: fixed;
        z-index: 3;
        top: 46px;
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .two-column-layout {
    &__outer {
      display: none;
    }

    &__main {
      padding: 60px 18px;
    }

    &__mobile-controls {
      display: block;
      position: fixed;
      z-index: 3;
      border-bottom: 1px solid #eff2f6;
    }
  }
}
</style>
